<div class="row">

    <div class="col-lg-12 col-xl-3 pl-0" style=" margin-top: 11px">
        <div class="row mb-5">
            <div class="col-xl-12 col-lg-5 smallCalendar" >
              
                <mat-calendar [selected]="selectedDate" (selectedChange)="onSelect($event)" [dateClass]="dateClass.bind(this)">
                </mat-calendar>
            </div>
            <hr>
            <div class="col-xl-12 col-lg-5 card-statut">
                <div class=" titleBoxes">
                <p class="mx-3 my-3" > {{"languages.miniListing.AfficherPar" | translate}} </p>
            </div>
                <div class="row mt-2">
                    <ng-container class="custom-checkbox-container" *ngFor="let item of filters; let i = index">
                        <div class="col-md-6 mb-2">
                            <mat-checkbox class="custom-checkbox" [checked]="true" (change)="onCheckboxChange($event, item)">
                                {{ item.name }}
                            </mat-checkbox>
                        </div>
                        
                        <div *ngIf="(i + 1) % 2 === 0" class="w-100"></div>
                    </ng-container>
                </div>
            </div>
            
            <div class="col-xl-12 col-lg-5 card-statut">
               
                    <div class="row titleBoxes">
                        <div class="col-10">
                            <p class="my-3" >{{"languages.listing.activities" | translate}} </p>
                        </div>
                       
                        <div class="col-2 d-flex align-items-center justify-content-center ">
                    <button type="button" class="btn-dt-add "  (click)="unattachedEvents()">
                        <i class="fa-solid fa-plus"></i>
                    </button>
                </div>
                    </div>
                    <div  *ngIf="list_upcoming_rdvs?.length >0 && updateListRdv" [ngClass]="{'li-next-rdv-container': list_upcoming_rdvs.length > 5 }" class="  mt-2" id="todayActivities">
                        <div class="li-next-rdv row my-3" *ngFor="let rdv of list_upcoming_rdvs" >
                            <div class="col-2">
                                <div class="activiteIcon d-flex justify-content-center align-items-center" [ngStyle]="{'background-color': rdv?.backgroundColor}">
                                    <i *ngIf="rdv?.icon" class="fa-solid {{rdv?.icon}} fa-icon-style" ></i>
                                </div>
                            </div>
                            <div class="col-10">
                                <a *ngIf="rdv.type !=='reunion'" class="url-next-rdv" href="{{rdv.url}}" title="{{ rdv.title }}" target="_blank">{{expandFileName(capitalizeFirstLetter(rdv.title))}}</a>
                                <p *ngIf="rdv.type ==='reunion'" class="m-0 url-next-rdv" (click)="clickTodayActivity(rdv)" > {{expandFileName(capitalizeFirstLetter(rdv.title))}}</p>
                                <p class="dateActivities m-0">
                                    De: {{parseDate(rdv.start) | date:'dd-MM-yyyy HH:mm'}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="customHeight row  " *ngIf=" list_upcoming_rdvs?.length===0 && updateListRdv">
                  
                        <p class="col-12 d-flex align-items-center justify-content-center noActivities"> {{'languages.Agenda.noActivities' | translate}}  </p>
                       </div>
                       <div class="customHeight d-flex align-items-center justify-content-center "*ngIf="!updateListRdv">
                
                            <div class="col-12 text-center">
                                <div class="d-flex align-items-center justify-content-center ">
                                    <mat-spinner [diameter]="20"></mat-spinner>
                                </div>
                            </div>
                       
                </div>
              
            </div>
        </div>
    </div>

    <div class="col-lg-12 col-xl-9 card" style="padding: 1%; border-radius: 15px; margin: 10px 0px 42px -10px">
        <div class="calendar-container">
            <div *ngIf="!updateListRdv" class="loader-span">
                <div class="row" style="margin-top: 150px">
                    <div class="col-12 text-center">
                        <mat-spinner [diameter]="30" style="margin: auto"></mat-spinner>
                    </div>
                </div>
            </div>
            <div [ngStyle]="{'opacity': updateListRdv? '1':'1'}" class="row">
                <div class="col-12 d-flex">
                    <div class="col-6 ">
                        <h2> {{"languages.navbar.Agenda" | translate}}</h2>
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                        <button type="button" class="btnImport mr-3 " (click)="importCalendar()">
                            <i class=" fa-duotone fa-arrow-down-to-bracket fa-icon-style mr-2"></i>
                            {{"languages.Agenda.ImportCalendar" | translate}}
                        </button>
                        <button type="button" class="btnExport" (click)="exportCalendar()">
                            <i class="fa-duotone fa-arrow-up-from-bracket fa-icon-style mr-2"></i>
                            {{"languages.Agenda.ExportCalendar" | translate}}
                            
                        </button>
                    </div>
                </div>
               
                <div class="row mb-1 ml-2">
                    <div class="div-1 mx-1">
                        <i class="fa-solid fa-square fa-icon-style mt-1 mr-1" style="color:#FDBB69"></i>
                        <span>  {{"languages.navbar.Tasks" | translate}}</span>
                    </div>
                    <div class="div-1 mx-1">
                        <i class="fa-solid fa-square fa-icon-style mt-1 mr-1" style="color:#99CDFF"></i>
                        <span>  {{"languages.navbar.OppDetailsTickets" | translate}}</span>
                    </div>
                    <div class="div-1 mx-1">
                        <i class="fa-solid fa-square fa-icon-style mt-1 mr-1" style="color:#83D199"></i>
                        <span>  {{"languages.Agenda.events" | translate}}</span>
                    </div>
                    <div class="div-1 mx-1">
                        <i class="fa-solid fa-square fa-icon-style mt-1 mr-1" style="color:#FF808B"></i>
                        <span>  {{"languages.Agenda.RDVs" | translate}}</span>
                    </div>
                </div>
                <div class="col-12">
                    <full-calendar #calendar [options]="calendarOptions">
                    </full-calendar>
                </div>
            </div>
        </div>
    </div>

   
</div>
<ng-template #OppUpdateRdvModal let-modal>
    <div class="modal-header">
        <p class="modal-title text-center m-0 justify-content-center w-100 " id="modal-basic-title"> {{"languages.Agenda.rdvSetting" | translate}} </p>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismissAll()">
      <span aria-hidden="true" (click)="modalService.dismissAll()">×</span>
    </button>
    </div>
    <div class="modal-body mx-5">
        <div class="form-group">
            <mat-form-field class="example-full-width">
                <mat-label> {{"languages.listing.Titre" | translate}}</mat-label>
                <input matInput type="text" [formControl]="eventTitleControl">
                <button mat-button *ngIf="eventTitleControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="eventTitleControl.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input matInput [ngxMatDatetimePicker]="picker1" placeholder="{{'languages.affaire.startDate' | translate}}" (dateChange)="changerdatedebut($event)" [formControl]="dateDebutControl" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker1 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" (selectedChanged)="onDate($event)">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
       
            <mat-form-field class="example-full-width">
                <input matInput [ngxMatDatetimePicker]="picker2" placeholder="{{'languages.affaire.dateFin' | translate}}" (dateChange)="changerdatefin($event)" [formControl]="dateFinControl" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker2 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                </ngx-mat-datetime-picker>
            </mat-form-field>
       
        <mat-form-field class="example-full-width">
            <mat-label>{{"languages.opportunite.details.link" | translate}}</mat-label>
            <input matInput type="text" [formControl]="eventLinkControl">
            <button mat-button *ngIf="eventLinkControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="eventLinkControl.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>{{"languages.ticket.description" | translate}}</mat-label>
            <input matInput type="text" [formControl]="eventDescriptionControl">
            <button mat-button *ngIf="eventDescriptionControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="eventDescriptionControl.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
        </mat-form-field>
    </div>
 

    <div class="row  d-flex justify-content-center align-items-center mb-3">
        <button (click)="updateRdvDetails()" type="button" class="btn-dt-save-popup mr-2">
            <i class="fa-solid fa-circle-check fa-icon-style mr-1"></i>
            {{"languages.buttons.enregistrer" | translate}}
          </button> 
          <button (click)="deleteRdv()" type="button" class="btn-dt-delete-popup mr-2">
            <i class="fa-solid fa-trash fa-icon-style  fa-lg"></i>
            {{"languages.buttons.supprimer" | translate}}
          </button> 
<button type="button" class="btn-dt-fermer ml-2 mr-1" (click)="modalService.dismissAll()">
    <i class="fa-solid fa-circle-xmark fa-lg"></i> 
    {{'languages.buttons.fermer' | translate}}
</button>
     

</div>

</ng-template>
<ng-template #OppCreateRdvModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center m-0 justify-content-center w-100" id="modal-basic-title2"> {{"languages.Agenda.addEvent" | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true" (click)="modalService.dismissAll()">×</span>
    </button>
    </div>
    <div class="modal-body mx-5">
        <div class="form-group">
            <mat-form-field class="example-full-width">
                <mat-label>{{"languages.listing.Titre" | translate}}</mat-label>
                <input matInput type="text" [formControl]="eventTitleControl">
                <button mat-button *ngIf="eventTitleControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="eventTitleControl.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <input matInput [ngxMatDatetimePicker]="picker1" placeholder="{{'languages.affaire.startDate' | translate}}"  (dateChange)="changerdatedebut($event)" [formControl]="dateDebutControl" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker1 [showSpinners]="showSpinners" (selectedChanged)="onDate($event)" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
       
            <mat-form-field class="example-full-width">
                <input matInput [ngxMatDatetimePicker]="picker2" (dateChange)="changerdatefin($event)" placeholder="{{'languages.affaire.dateFin' | translate}}" [formControl]="dateFinControl" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker2 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        
            <mat-form-field class="example-full-width">
                <mat-label>{{"languages.opportunite.details.link" | translate}}</mat-label>
                <input matInput type="text" [formControl]="eventLinkControl">
                <button mat-button *ngIf="eventLinkControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="eventLinkControl.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>{{"languages.ticket.description" | translate}}</mat-label>
                <input matInput type="text" [formControl]="eventDescriptionControl">
                <button mat-button *ngIf="eventDescriptionControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="eventDescriptionControl.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
            </mat-form-field>

    </div>

    <div class="row  d-flex justify-content-center align-items-center mb-3">
        <button (click)="createRdvDetails()" type="button" class="btn-dt-save-popup mr-2">
            <i class="fa-solid fa-circle-check fa-icon-style mr-1"></i>
            {{"languages.buttons.enregistrer" | translate}}
          </button> 
<button type="button" class="btn-dt-fermer ml-2" (click)="modalService.dismissAll()">
    <i class="fa-solid fa-circle-xmark fa-lg mr-1"></i>
    {{'languages.buttons.fermer' | translate}}
</button>
     

</div>


    
</ng-template>
<ng-template #ImportCalendar let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center m-0 justify-content-center w-100" id="ImportCalendar"> {{"languages.Agenda.ImportCalendar" | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true" (click)="modalService.dismissAll()">×</span>
    </button>
    </div>
    <div class="modal-body d-flex flex-column  justify-content-center text-center mx-5">
      <p> {{"languages.Agenda.apartirDe" | translate}}</p>
      <div class="form-group row d-flex justify-content-center">
        <mat-checkbox class="custom-checkbox ml-0 "   [(ngModel)]="importFromUrl" (change)="onImportOptionChange('url')">
            {{"languages.listing.URL" | translate}}
        </mat-checkbox>
        <mat-checkbox class="custom-checkbox"  [(ngModel)]="importFromFile" (change)="onImportOptionChange('file')">
            {{"languages.listing.Dossier" | translate}}
        </mat-checkbox>
    </div>
    <div *ngIf="importFromUrl">
  
            <mat-form-field class="w-100">
                <mat-label>{{"languages.Agenda.enterUrl" | translate}}</mat-label>
                <input matInput  [formControl]="importUrl">
                <mat-error *ngIf="importUrl.hasError('invalidUrl') && (importUrl.dirty || importUrl.touched)">
                  {{"languages.role.formLinkInvalid" | translate}}
                </mat-error>
            </mat-form-field>
      
    </div>
    <div *ngIf="importFromFile">
        <div class="form-group mt-2">
            <label for="fileField" class="attachment">
  <div class=" btn-file mt-1">
    <div class="btn-file__actions mt-1">
      <div class="btn-file__actions__item mt-1 col-xs-12 text-center " id="file-upload-container">
        <div class="btn-file__actions__item--shadow mt-1">

<div class="row d-flex justify-content-center align-items-center ">
          
          <i class="fa-solid fa-upload fa-icon-size mr-2"></i>
          <p class="import m-0">{{'languages.affaire.importFile' | translate}}</p>
        </div>
        </div>
      </div>
    </div>
  </div>
  <input (change)="onFileChange($event) " accept=".ics"  name="file" type="file" id="fileField">
  <p  class="errorMsg  mt-3" *ngIf="!importfileName">
    <i class="fa-solid fa-circle-exclamation mr-2"></i>
                  
                  {{'languages.opportunite.details.typeFile' | translate}} ics
              </p>
</label>

        </div>
        

        <div *ngIf="importFile" class="col-md-12 fileComment d-flex  align-items-center mb-2 py-2 " >

            <button  type="button" (click)="removefile()" class="deleteFile">
              <img  src='../../../assets/icons/deleteIcon.svg'>
              </button>

            
            <img  src='../../../assets/img/icsFile.png'>


           <span class="file-name  links ml-2">{{expandName(importfileName)}}  </span>
          

        </div>
   
    </div>

    <div *ngIf="getImportUrlLoader" class="d-flex align-items-center justify-content-center p-2 ">
        <mat-spinner [diameter]="20"></mat-spinner>
    </div>
    <div class="row d-flex justify-content-center mt-2">
        
        
    <button *ngIf="importfileName || importUrl.value" [disabled]="importFromUrl && (importUrl.hasError('invalidUrl') && (importUrl.dirty || importUrl.touched))" (click)="importCalendarInfo()" type="button" class="btn-dt-save-popup ">
        <i class="fa-solid fa-circle-check fa-icon-style mr-1"></i>
        {{"languages.buttons.confirmer" | translate}}
      </button> 
    </div>
    </div>



    
</ng-template>

<ng-template #ExportCalendar let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center m-0 justify-content-center w-100" id="ExportCalendar"> {{"languages.Agenda.ExportCalendar" | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true" (click)="modalService.dismissAll()">×</span>
    </button>
    </div>
    <div class="modal-body d-flex flex-column  justify-content-center text-center mx-5">
      <p> {{"languages.Agenda.apartirDe" | translate}}</p>
      <div class="form-group row d-flex justify-content-center">
        <mat-checkbox class="custom-checkbox ml-0 "   [(ngModel)]="exportFromUrl" (change)="onExportOptionChange('url')">
            {{"languages.listing.URL" | translate}}
        </mat-checkbox>
        <mat-checkbox class="custom-checkbox"  [(ngModel)]="exportFromFile" (change)="onExportOptionChange('file')">
            {{"languages.listing.Dossier" | translate}}
        </mat-checkbox>
    </div>
    <div *ngIf="exportFromUrl">
        <div *ngIf="exportUrl && !getExportUrlLoader"class="btnExport d-flex align-items-center justify-content-center p-2">
            <p class="m-0" [title]="exportUrl? exportUrl :''"> {{expandFileName(exportUrl)}}</p>
            <div class="tooltip-container" (click)="copyToClipboard(exportUrl)">
                <i class="fa-duotone fa-copy ml-4" role="button"></i>
                <span class="tooltip-text" [class.show]="tooltipVisible">{{ 'languages.groupedactions.urlCopied' | translate }}</span>
            </div>        </div>
        <div *ngIf="getExportUrlLoader" class="btnExport d-flex align-items-center justify-content-center p-2 ">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
    </div>
    <div *ngIf="exportFromFile">
        <div *ngIf="filenameExport && !getExportDocLoader" class="btnExport d-flex align-items-center justify-content-center p-2">
            <p class="m-0" [title]="filenameExport? filenameExport :''"> {{expandFileName(filenameExport)}}</p>
            <i class="fa-duotone fa-download ml-4 " role="button" (click)="downloadFile()"></i>
            
        </div>
        <div *ngIf="getExportDocLoader" class="btnExport d-flex align-items-center justify-content-center p-2 ">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
   
    </div>

      
    </div>



    
</ng-template>
